<template lang="pug">
footer.FcwProfileFooter(:style="style")
  template(v-if="customerServiceLinks.length")
    span.FcwProfileFooter__customerServiceTitle {{ customerServiceTitle }}
    ul.FcwProfileFooter__customerService
      .FcwProfileFooter__customerServiceLinks
        li.FcwProfileFooter__customerServiceLink(
          v-for="(link, index) in customerServiceLinks"
          :key="index"
        )
          FcwRouterLink(
            v-if="link.location"
            :location="link.location"
            hide-arrow
            :color="customerServiceTextColor"
            @click="trackOpenAContactLink"
          )
            FIcon(:name="link.icon")
            span {{ link.text }}
          template(v-else)
            FIcon(:name="link.icon")
            span {{ link.text }}
  ul.FcwProfileFooter__legal
    li(
      v-for="(link, index) in legalLinks"
      :key="index"
    )
      FcwRouterLink(
        :location="link.location"
        target="_blank"
        hide-arrow
        :color="legalTextColor"
      ) {{ link.text }}
    .FcwProfileFooter__editorText
      span {{ editorText }}
      FcwRouterLink.FcwProfileFooter__editorLink(
        color="neutral--light-5"
        location="https://fifteen.eu"
        hide-arrow
      ) Fifteen
  .FcwProfileFooter__localeSelect
    FcwLocaleSelect(size="small")
</template>

<style lang="stylus">
.FcwProfileFooter
  display flex
  flex-direction column
  gap rem(24)

.FcwProfileFooter__customerService,
.FcwProfileFooter__legal
  padding 0

.FcwProfileFooter__customerService,
.FcwProfileFooter__customerServiceTitle
  color var(--FcwProfileFooter--customerServiceTextColor)

.FcwProfileFooter__customerServiceLinks
  .FcwProfileFooter__customerServiceLink:first-child
    margin-top 0

.FcwProfileFooter__customerServiceLink
  display flex
  align-items center
  justify-content flex-start
  margin-top rem(8)
  use-font('subtitle-1')

  > * + *,
  * > * + *
    margin-left rem(8)

.FcwProfileFooter__legal,
.FcwProfileFooter__legal .FLink
  use-font('subtitle-2')
  color var(--FcwProfileFooter--legalTextColor)
  font-weight normal

.FcwProfileFooter__legal .FLink
  font-weight 600

.FcwProfileFooter__editorText
  margin-top rem(8)

.FcwProfileFooter__editorLink
  font-weight 600
  margin-left 0

  &::before
    content '\00a0'

.FcwProfileFooter__localeSelect
  width fit-content
  transform translateX(-12px)
</style>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

export interface FcwProfileFooterCustomerServiceLink {
  /**
   * Icon of a footer customer service link
   */
  icon: Icon;
  /**
   * Text of a footer customer service link
   */
  text: string;
  /**
   * Location of a footer customer service link
   */
  location?: RouteLocationRaw;
  /**
   * Type of a footer customer service link
   */
  type?: 'tel' | 'mailto';
}

export interface FcwProfileFooterLegalLink {
  /**
   * Text of a footer legal link
   */
  text: string;
  /**
   * Location of a footer legal link
   */
  location: RouteLocationRaw;
}

export interface FcwProfileFooterProps {
  /**
   * Customer service section title
   */
  customerServiceTitle: string;
  /**
   * List of customer service links
   */
  customerServiceLinks?: FcwProfileFooterCustomerServiceLink[];
  /**
   * Color used for customer service title and links
   */
  customerServiceTextColor?: Color;
  /**
   * List of legal links
   */
  legalLinks?: FcwProfileFooterLegalLink[];
  /**
   * Color used for legal section
   */
  legalTextColor?: Color;
  /**
   * Text to display under legal section
   */
  editorText: string;
}

const props = withDefaults(defineProps<FcwProfileFooterProps>(), {
  customerServiceTextColor: 'primary--light-1',
  legalTextColor: 'neutral--dark-2',
  customerServiceLinks: () => [],
  legalLinks: () => [],
});

const { track } = useUserTracking();

const style = computed(
  (): Style => ({
    '--FcwProfileFooter--customerServiceTextColor': getCssColor(
      props.customerServiceTextColor
    ),
    '--FcwProfileFooter--legalTextColor': getCssColor(props.legalTextColor),
  })
);

function trackOpenAContactLink(
  type?: FcwProfileFooterCustomerServiceLink['type']
): void {
  if (!type) return;

  switch (type) {
    case 'tel':
      track.openAContactLink({
        contactMode: 'phone',
      });
      break;
    case 'mailto':
      track.openAContactLink({
        contactMode: 'email',
      });
      break;
  }
}
</script>
